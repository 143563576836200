<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-close route-name="feature_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <input type="hidden" v-model="feature.id">
                    <div class="form-group">
                      <app-checkbox
                        v-model="feature.enabled"
                        id="feature_enabled"
                        :label="$t('feature.enabled')"
                      >
                      </app-checkbox>
                    </div>
                    <app-input
                      v-model="feature.component"
                      id="feature_component"
                      :label="$t('feature.component')"
                      readonly
                    >
                    </app-input>
                    <app-input
                      v-model="feature.name"
                      id="feature_name"
                      :label="$t('feature.name')"
                      readonly
                    >
                    </app-input>
                    <app-input
                      v-model="feature.description"
                      id="feature_description"
                      :label="$t('feature.description')"
                    >
                    </app-input>
                    <app-textarea
                      v-model="feature.data"
                      id="feature_data"
                      :label="$t('feature.data')"
                    >
                    </app-textarea>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Input from '../../components/form/inputs/Input'
import Textarea from '../../components/form/Textarea'
import FeatureModel from '../../model/Feature'
import ButtonClose from '../../components/shared/ButtonClose'
import Checkbox from '../../components/form/Checkbox'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'FeatureNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      feature: this._.cloneDeep(FeatureModel)
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appTextarea: Textarea,
    appButtonClose: ButtonClose,
    appCheckbox: Checkbox
  },
  computed: {
    isSaveButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.SYSTEM_FEATURE_PERMISSIONS.saveButton
      return this.$store.getters['user/hasPermission'](permission)
    }
  }
}
</script>
